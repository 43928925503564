  

<template>

    <div class="px-5 sm:px-52 mx-auto my-20">
        <div style="font-size: 20px">
            <div>
                <h2 class="font-extrabold color-purple text-3xl RobotoMedium" >Ahorro</h2>
                <div class="liner-yellow"></div>
                <p class="color-grey mt-10 font-semibold	">
                    Ahorrar es destinar una parte de los ingresos para conseguir una meta o crear un fondo que permita enfrentar una emergencia.
                    El ahorro se puede usar para pagar unas vacaciones, comprar una computadora, cambiar el coche, enfrentar una enfermedad.
                    Ahorrar imprica prevenir y prever anticipadamente para enfrentar el futuro y caminar con responsabilidad en el presente, por ello,
                    la cultura del ahorro beneficia a personas, familias, comunidades y naciones.
                </p>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <h3 class="font-extrabold color-purple text-2xl RobotoMedium">¿Qué es el ahorro?</h3>
                <p class="color-grey text-info">Es aquella parte del ingreso que no se destina al consumo de bienes o servicios.</p>
                <p class="color-grey text-info">Es la diferencia que existe entre los ingresos y los gastos. Es decir, el monto de los ingresos que no se gastó y que se puede guardar para usarlo en el futuro.</p>
                <p class="color-grey text-info">El ahorro es el hábito de guardar una parte de nuestros ingresos para conseguir una meta, cumplir un sueño o crear un fondo que nos permita enfrentar alguna emergencia.</p>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Ventajas de ahorrar</h3>
                <div class="relative flex py-5 items-center">
                    <div class="flex-grow border-t border-gray-400"></div>
                </div>
                
                <p class="color-grey text-info">
                    <li type="disc">El ahorro se puede usar para alcanzar tus metas financieras o invertir.</li>
                    <li type="disc">Ahorrar implica prevenir y prever anticipadamente para enfrentar el futuro.</li>
                    <li type="disc">Enfrentar gastos inesperados como enfermedades o accidentes.</li>
                    <li type="disc">Contar con un fondo para vivir la vejez con tranquilidad.</li>
                    <li type="disc">El ahorro fortalece el bienestar de las familias, incrementa la estabilidad económica personal, familiar y patrimonial.</li>
                </p>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Requisitos para ahorrar</h3>
                <div class="relative flex py-5 items-center">
                    <div class="flex-grow border-t border-gray-400"></div>
                </div>
                
                <p class="color-grey text-info">
                    <li type="disc">Solicitud debidamente llenada y firmada.</li>
                    <li type="disc">Autorizo de ahorro firmado.</li>
                    <li type="disc">Identificación Oficial Vigente: Credencial para votar y/o Pasaporte.</li>
                    <li type="disc">CURP</li>
                    <li type="disc">Constancia de situación fiscal actualizada.</li>
                    <li type="disc">Comprobante de domicilio actualizado.</li>
                    <li type="disc">Acta de nacimiento.</li>
                </p>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

    </div>

</template>
 
<script>
export default {
  data() {
      return {
        };
      },
  name: 'SavingsProduct',
  methods:{
  }
}

</script>

<style scoped>
#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
</style>

<style scoped>
.liner-yellow {
  width: 100px;
  background: #f8c603;
  height: 5px;
}
.text-info{
  font-family: "RobotoRegular";
}
.color-purple {
  color: #810042;
}
.color-grey {
  color: #726659;
}
</style>